<style lang="scss" scoped>
#mapContainer {
    width: 100vw;
    height: 100vh;
}

.terminal_container {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    margin: 10px;
    padding: 0 10px;
    font-size: 14px;

    .title {
        height: 45px;
        line-height: 45px;
    }

    .page {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        li {
            display: flex;
            flex: 1;
            justify-content: center;
            margin: 10px;

            &:first-child {
                border-right: 1px solid #000;
            }

            &.disabled {
                color: #999;
            }
        }

    }
}

.date_picker {
    position: absolute;
    left: 290px;
    top: 14px;
    z-index: 99;
    display: flex;
}

.el-date-editor--date {
    margin-left: 10px;
}

.control_bar {
    position: absolute;
    right: 0px;
    top: 60px;
    z-index: 99;
    background-color: #fff;
    padding: 5px 5px 0 5px;
    min-width: 185px;
    font-size: 14px;
    color: #666;
    padding-right: 10px;

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
    }
}

.playback-btn {
    font-size: 22px;
    color: #319bf7;
    cursor: pointer;
}
</style>
<template>
    <div class="container" v-loading="loading">
        <div class="terminal_container">
            <h3 class="title">终端查询</h3>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="">
                    <el-input style="width: 140px;" v-model="terminal.input" clearable placeholder="关键词"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onTerminalSubmit">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                :data="terminal.data"
                border
                highlight-current-row
                @row-click="terminalRowClick"
                style="width: 100%;cursor: pointer;margin-top: -10px;height:calc(100vh - 200px);overflow: scroll">
                <el-table-column
                    prop="name"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="locatetime"
                    label="状态"
                    width="50">
                    <template slot-scope="scope">
                        <span v-if="+new Date() - scope.row.locatetime < offlineTime">在线</span>
                        <span v-else="">离线</span>
                    </template>
                </el-table-column>
            </el-table>
            <!--<ul class="page">-->
            <!--<li :class="{'disabled':terminal.pageIndex===1}" @click="terminalPageClick(-1)">上一页</li>-->
            <!--<li :class="{'disabled':!terminal.hasMore}" @click="terminalPageClick(1)">下一页</li>-->
            <!--</ul>-->
        </div>
        <div class="date_picker">
            <province-and-city style="margin-bottom: 10px;width: 160px;"
                               @input="handleSelectedCity"
                               v-model="terminal.city">
            </province-and-city>
            <el-date-picker
                v-if="control.model"
                style="width: 200px;margin-left: 10px"
                v-model="track.starttime"
                type="datetime"
                @change="handleDatePickerChange"
                placeholder="选择开始时间">
            </el-date-picker>
            <el-date-picker
                v-if="control.model"
                style="width: 200px;margin-left: 10px"
                v-model="track.endtime"
                type="datetime"
                @change="handleDatePickerChange"
                placeholder="选择结束时间">
            </el-date-picker>
        </div>
        <div class="control_bar">
            <ul>
                <li>
                    模式&nbsp;&nbsp;
                    <el-switch
                        size="small"
                        v-model="control.model"
                        @change="handleModelChange"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="轨迹"
                        inactive-text="终端"
                    >
                    </el-switch>
                </li>
                <li v-if="!control.model">
                    <el-radio-group v-model="control.state"
                                    size="small"
                                    @change="handelControlState">
                        <el-radio-button label="全部">{{ `全部${this.terminal.count}` }}</el-radio-button>
                        <el-radio-button label="在线">{{ `在线${this.terminal.onLineCount}` }}</el-radio-button>
                        <el-radio-button label="离线">{{ `离线${this.terminal.offLineCount}` }}</el-radio-button>
                    </el-radio-group>
                </li>
                <!--<li v-if="!control.model">-->
                <!--热力图&nbsp;&nbsp;-->
                <!--<el-switch-->
                <!--size="small"-->
                <!--@change="handleHeatMapControl"-->
                <!--v-model="control.heatmap"-->
                <!--active-color="#13ce66"-->
                <!--inactive-color="#ff4949">-->
                <!--</el-switch>-->
                <!--</li>-->
                <li v-if="control.model">
                    轨迹回放&nbsp;&nbsp;
                    <span @click="trackPlayback"
                          class="icon playback-btn"
                          type="primary"
                          :class="!draw.play?'icon-bofang':'icon-zanting'">
                    </span>
                </li>
            </ul>
        </div>
        <div id="mapContainer"></div>
    </div>

</template>

<script type="text/ecmascript-6">
import {getQueryString} from '@/common/js/Utils'
import provinceAndCity from '@/components/provinceAndCity/'
import moment from "moment";

const refresh_interval = 30 * 1000;

export default {
    name: "trackManage",
    //定义模版数据
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            map: null,
            sid: getQueryString("service_id") || 24378,
            startTime: getQueryString("start_time"),
            endTime: getQueryString("end_time"),
            offlineTime: 10 * 60 * 1000,
            terminal: {
                count: 0,
                onLineCount: 0,
                offLineCount: 0,
                input: null,
                data: [],
                pageSize: 100,
                pageIndex: 1,
                keywords: '',
                type: 1,
                hasMore: true,
                city: ['上海', '上海市']
            },
            track: {
                date: +new Date(),
                points: [],
                data: [],
                tid: null,
                trid: null,
                terminalname: null,
                starttime: moment().startOf('d').valueOf(),
                endtime: undefined,
                page: 1,
                pageSize: 999,
            },
            control: {
                heatmap: null,
                playback: false,
                state: "全部",
                model: false
            },
            draw: {
                pathSimplifierIns: null,
                play: false,
                navg: null
            },
            mark: {
                pointSimplifierIns: null
            },
            img: {
                red: {},
                blue: {},
                gray: {}
            },
            loading: true
        }
    },
    //计算属性
    computed: {},
    components: {
        provinceAndCity
    },
    activated() {
//            if(this.Debug) this.sid = 24378;
//            else this.sid = 29677;
//                // this.sid = getQueryString("service_id") || 24378;
//            this.init();
    },
    //主件被加载完成
    mounted: function () {
        if (this.Debug) this.sid = 24378;
        else this.sid = 29677;
        this.init();
    },
    //定义事件方法
    methods: {
        async init() {
            if (!window.AMap) {
                const url = '//webapi.amap.com/maps?v=1.4.15&key=0bb2ba3ff63722595ab21b36357841cc&&plugin=AMap.Geocoder&callback=onMapLoad';
                const mapUiUrl = '//webapi.amap.com/ui/1.0/main.js?v=1.0.11';
                //加载地图
                await this.loadScrip(url, true);
                this.map = new window.AMap.Map('mapContainer', {
                    resizeEnable: true,
                    center: [121.473658, 31.230378],
                    zoom: 11
                });
                await this.loadScrip(mapUiUrl);
            }
            //加载终端
            this.getTerminals();
        },
        //加载地图
        loadScrip(url, load) {
            return new Promise(function (resolve) {
                window.onMapLoad = function () {
                    resolve(true);
                }
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.src = url;
                script.onload = function () {
                    if (!load)
                        resolve(true);
                }
                document.head.appendChild(script);
            })

        },
        //搜索终端
        async getTerminals() {
            clearInterval(this.terminalTimer);

            if (this.terminal.pageIndex === 1)
                this.terminal.data = [];
            //行政区域搜索
            let url = 'terminal/districtsearch';
            //关键字搜索
            if (this.terminal.keywords)
                url = 'terminal/search';

            let data = {
                sid: this.sid,
                page: this.terminal.pageIndex,
                keywords: this.terminal.keywords || this.terminal.city[1],
                sortrule: 'lastloctime:desc',
                pagesize: this.terminal.pageSize
            }

            const ret = await this.httpTrack(url, data, 'POST');
            if (ret.errcode === 10000 && ret.data) {
                this.terminal.data = this.terminal.data.concat(ret.data.results);
                this.terminal.count = ret.data.count;
                this.control.model = false;
                if (ret.data.count !== this.terminal.pageSize) {
                    this.handleModelChange();
                    clearInterval(this.terminalTimer);
                    this.terminalTimer = setTimeout(() => {
                        this.terminal.pageIndex = 1;
                        this.autoRefresh = false;
                        this.getTerminals();
                    }, refresh_interval);
                } else {
                    this.terminal.pageIndex++;
                    this.getTerminals()
                }
            }
            this.loading = false;
        },
        //终端翻页
        terminalPageClick(index) {
            if ((index === -1 && this.terminal.pageIndex === 1) || (!this.terminal.hasMore && index === 1))
                return;
            this.terminal.pageIndex += index;
            this.loading = true;
            this.getTerminals();
        },
        //输入关键词查询
        onTerminalSubmit() {
            this.terminal.keywords = this.terminal.input;
            this.terminal.type = 1;
            this.terminal.pageIndex = 1;
            this.terminal.hasMore = true;
            this.terminal.data = [];
            this.loading = true;
            this.getTerminals();
        },
        //点击某一个终端
        async terminalRowClick(row) {
            this.track.tid = row.tid;
            this.track.page = 1;
            this.track.data = [];
            // this.track.starttime = null;
            // this.track.endtime = null;
            this.track.date = moment(this.track.starttime).format('YYYY-MM-DD');
            this.track.terminalname = row.name;
            this.control.model = true;
            this.mark.pointSimplifierIns && this.mark.pointSimplifierIns.hide();
            this.trackSearch();
            this.loading = true;
            clearTimeout(this.terminalTimer);
        },
        //轨迹查询
        async trackSearch() {
            clearInterval(this.trackTimer);
            if (!this.track.starttime) {
                this.track.starttime = +new Date(this.track.date + ' 00:00:00');
                if (this.startTime)
                    this.track.starttime = this.startTime;
            }
            const now = new Date().toLocaleDateString();
            const trackDateLocal = new Date(this.track.date).toLocaleDateString();
            if (!this.track.endtime) {
                if (trackDateLocal !== now)
                    this.track.endtime = +new Date(this.track.date + ' 23:59:59');
                else
                    this.track.endtime = +new Date();
                if (this.endTime)
                    this.track.endtime = this.endTime;
            }
            const data = {
                sid: this.sid,
                tid: this.track.tid,
                trid: this.track.trid,
                starttime: moment(this.track.starttime).valueOf(),
                endtime: moment(this.track.endtime).valueOf(),
                page: this.track.page,
                pagesize: this.track.pageSize,
                accuracy: 100,
                recoup: 0,
                gap: 500,
                order: 0
            };
            const ret = await this.httpTrack('terminal/points', data);
            if (ret.errcode === 10000 && ret.data && ret.data.points) {
                if (data.page === 1)
                    this.track.data = ret.data.points;
                else
                    this.track.data = this.track.data.concat(ret.data.points);
                if (ret.data.count !== this.track.data.length) {
                    this.track.page++;
                    this.trackSearch();
                } else {
                    this.drawInit();
                    clearInterval(this.trackTimer);
                    if (trackDateLocal === now) {
                        this.trackTimer = setTimeout(() => {
                            this.track.page = 1;
                            this.trackSearch();
                        }, refresh_interval);
                    }
                    this.loading = false;
                }
            } else {
                if (data.page === 1) {
                    this.track.data = [];
                    this.draw.pathSimplifierIns && this.draw.pathSimplifierIns.setData([])
                    this.map.clearMap();
                }
                this.loading = false;
            }

        },
        //绘制
        drawInit() {
            const that = this;
            if (that.track.data.length < 1)
                return;
            that.track.data.sort((a, b) => {
                return a.locatetime - b.locatetime;
            })
            that.track.points = [];
            that.track.data.forEach((data) => {
                const point = data.location.split(',');
                that.track.points.push([Number(point[0]), Number(point[1])]);
            })

            if (that.draw.pathSimplifierIns) {
                //这里构建两条简单的轨迹，仅作示例
                that.draw.pathSimplifierIns.setData([{
                    name: '轨迹',
                    path: this.track.points
                }]);
            } else
                //加载PathSimplifier，loadUI的路径参数为模块名中 'ui/' 之后的部分
                window.AMapUI.load(['ui/misc/PathSimplifier'], function (PathSimplifier) {
                    if (!PathSimplifier.supportCanvas) {
                        alert('当前环境不支持 Canvas！');
                        return;
                    }
                    //启动页面
                    that.drawTrack(PathSimplifier);
                });
            that.drawTrackBothEnd(that.track.data);
        },
        drawTrackBothEnd(data) {
            this.map.clearMap();
            const lastPoint = data[data.length - 1]
            const startPoint = data[0].location.split(',');
            const endPoint = lastPoint.location.split(',');
            let endUrl = '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png';
            // 创建一个 Icon
            const startIcon = new window.AMap.Icon({
                // 图标尺寸
                size: new window.AMap.Size(25, 34),
                // 图标的取图地址
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                // 图标所用图片大小
                imageSize: new window.AMap.Size(135, 40),
                // 图标取图偏移量
                imageOffset: new window.AMap.Pixel(-9, -3)
            });

            // 将 icon 传入 marker
            const startMarker = new window.AMap.Marker({
                position: new window.AMap.LngLat(Number(startPoint[0]), Number(startPoint[1])),
                icon: startIcon,
                offset: new window.AMap.Pixel(-13, -30)
            });
            // 创建一个 icon
            let endIcon = new window.AMap.Icon({
                size: new window.AMap.Size(25, 34),
                image: endUrl,
                imageSize: new window.AMap.Size(135, 40),
                imageOffset: new window.AMap.Pixel(-95, -3)
            });

            let offset = new window.AMap.Pixel(-13, -30);
            if (+new Date() - lastPoint.locatetime < 600000) {
                let d = Math.ceil((lastPoint.direction % 360) / 45) * 45;
                d = d === 360 ? 0 : d;
                endUrl = `https://cdn.900etrip.com/static/track/car/blue/${d}.png`;
                let size = new window.AMap.Size(36, 36);
                offset = new window.AMap.Pixel(-18, -18)
                endIcon = new window.AMap.Icon({
                    size: size,
                    image: endUrl,
                    imageSize: size,
                });
            }
            // 将 icon 传入 marker
            const endMarker = new window.AMap.Marker({
                position: new window.AMap.LngLat(Number(endPoint[0]), Number(endPoint[1])),
                icon: endIcon,
                offset: offset
            });

            // 将 markers 添加到地图
            this.map.add([startMarker, endMarker]);
        },
        //开始绘制
        drawTrack(PathSimplifier) {

            const that = this;
            //创建组件实例
            that.draw.pathSimplifierIns = new PathSimplifier({
                zIndex: 100,
                map: this.map, //所属的地图实例
                getPath: function (pathData) {
                    //返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
                    return pathData.path;
                },
                getHoverTitle: function (pathData, pathIndex, pointIndex) {
                    //返回鼠标悬停时显示的信息
                    if (pointIndex >= 0) {
                        //鼠标悬停在某个轨迹节点上
                        const point = that.track.data[pointIndex]
                        console.log(point, 2332);
                        return 'location：' + point.location + '，speed:' + point.speed + '，direction:' + point.direction + "，time:" + moment(point.locatetime).format('YYYY-MM-DD HH:mm:ss');
                    }
                    //鼠标悬停在节点之间的连线上
                    return that.track.terminalname;
                },
                renderOptions: {
                    //轨迹线的样式
                    pathLineStyle: {
                        strokeStyle: 'red',
                        lineWidth: 6,
                        dirArrowStyle: true
                    },
                    pathLineSelectedStyle: {
                        strokeStyle: 'red',
                        lineWidth: 6,
                        dirArrowStyle: true
                    },
                    //开始点
                    startPointStyle: {
                        radius: 4,
                        fillStyle: '#109618',
                        lineWidth: 1,
                        strokeStyle: '#eeeeee'
                    },
                    //结束点
                    endPointStyle: {
                        radius: 4,
                        fillStyle: '#dc3912',
                        lineWidth: 1,
                        strokeStyle: '#eeeeee'
                    },
                    pathNavigatorStyle: {
                        width: 16,
                        height: 32,
                        //使用图片
                        content: PathSimplifier.Render.Canvas.getImageContent('https://webapi.amap.com/ui/1.0/ui/misc/PathSimplifier/examples/imgs/car.png', onload, onerror)
                    }
                }
            });

            //这里构建两条简单的轨迹，仅作示例
            that.draw.pathSimplifierIns.setData([{
                name: '轨迹',
                path: this.track.points
            }]);

        },
        //时间被确定
        handleDatePickerChange() {
            this.track.page = 1;
            this.track.data = [];
            // this.track.starttime = null;
            // this.track.endtime = null;
            this.track.date = moment(this.track.starttime).format('YYYY-MM-DD')
            this.loading = true;
            this.trackSearch();
        },
        //轨迹回放
        trackPlayback() {
            if (!this.draw.pathSimplifierIns)
                return;
            if (!this.control.playback) {
                this.draw.play = true;
                clearInterval(this.trackTimer);
                this.control.playback = true;
                //创建一个巡航器
                this.draw.navg = this.draw.pathSimplifierIns.createPathNavigator(0, //关联第1条轨迹
                    {
                        loop: false, //循环播放
                        speed: 10000
                    });
                this.draw.navg.on('pause', () => {
                    if (this.draw.navg.isCursorAtPathEnd()) {
                        this.draw.navg.destroy();
                        this.control.playback = false;
                        this.draw.play = false;
                        this.trackTimer = setTimeout(() => {
                            this.track.page = 1;
                            this.trackSearch();
                        }, refresh_interval);
                    }
                })
                this.draw.navg.start();
            } else {
                const status = this.draw.navg.getNaviStatus()
                if (status === 'moving') {
                    this.draw.navg.pause();
                    this.draw.play = false;
                }
                if (status === 'pause') {
                    this.draw.play = true;
                    this.draw.navg.resume();
                }
            }
        },
        //选中城市
        handleSelectedCity(val) {
            this.map.setCity(val[1])
            this.terminal.pageIndex = 1;
            this.loading = true;
            this.getTerminals();
        },
        //绘制海量点
        drawMassMarks() {
            const that = this;
            //加载PointSimplifier，loadUI的路径参数为模块名中 'ui/' 之后的部分
            if (!that.PointSimplifier)
                window.AMapUI.loadUI(['misc/PointSimplifier'], function (PointSimplifier) {

                    if (!PointSimplifier.supportCanvas) {
                        alert('当前环境不支持 Canvas！');
                        return;
                    }
                    that.PointSimplifier = PointSimplifier;
                    //启动页面
                    initPage(PointSimplifier);
                });
            else
                initPage(that.PointSimplifier)

            function initPage(PointSimplifier) {
                //创建组件实例
                if (!that.mark.pointSimplifierIns)
                    that.mark.pointSimplifierIns = new PointSimplifier({
                        map: that.map, //关联的map
                        zIndex: 300,
                        autoSetFitView: that.autoRefresh !== false,
                        compareDataItem: function (a) {
                            //数据源中靠后的元素优先，index大的排到前面去
                            return a.online ? -1 : 1;
                        },
                        getPosition: function (dataItem) {
                            //返回数据项的经纬度，AMap.LngLat实例或者经纬度数组
                            return dataItem.position;
                        },
                        getHoverTitle: function (dataItem) {
                            //返回数据项的Title信息，鼠标hover时显示
                            return dataItem.title;
                        },
                        renderConstructor: PointSimplifier.Render.Canvas.GroupStyleRender,
                        renderOptions: {
                            //点的样式
                            pointStyle: {
                                fillStyle: 'blue',
                                width: 36,
                                height: 36
                            },
                            getGroupId: function ({online, direction}) {
                                return `${(online ? 'blue' : 'gray')}-${direction}`
                            },
                            groupStyleOptions: function (groupId) {
                                return {
                                    pointStyle: {
                                        content: that.loadImage(PointSimplifier, groupId.split('-'), () => {
                                            that.mark.pointSimplifierIns.renderLater(200);
                                        })
                                    }
                                };
                            }
                        }
                    });
                let data = [];
                const nowTime = +new Date();
                let onLineCount = 0;
                let offLineCount = 0;
                that.terminal.data.forEach((item) => {
                    let obj = null, online = false;
                    if (item.location)
                        obj = {
                            online: false,
                            direction: item.location.direction,
                            title: item.name,
                            position: [
                                item.location.longitude,
                                item.location.latitude
                            ]
                        }
                    if (nowTime - item.locatetime < that.offlineTime) {
                        onLineCount++;
                        online = true;
                    } else
                        offLineCount++;
                    if ((online === false && that.control.state === '在线') || online === true && that.control.state === '离线')
                        obj = null;
                    obj && data.push({
                        ...obj,
                        online
                    })
                })
                that.terminal.onLineCount = onLineCount;
                that.terminal.offLineCount = offLineCount;
                //设置数据源，data需要是一个数组
                that.mark.pointSimplifierIns.setData(data);
                that.mark.pointSimplifierIns.show();
                //监听事件
//                    that.mark.pointSimplifierIns.on('pointClick pointMouseover pointMouseout',
//                        function (e, record) {
//                        }
//                    );
            }
        },
        //通过角度 图片加载 分类 缓存
        loadImage(PointSimplifier, [type, direction], onLoad) {
            const urls = {
                red: `https://cdn.900etrip.com/`,//红色小车
                blue: `https://cdn.900etrip.com/static/track/car/blue/`, //蓝色小车
                gray: `https://cdn.900etrip.com/static/track/car/gray/`//灰色小车
            }
            let d = Math.ceil((direction % 360) / 45) * 45;
            d = d === 360 ? 0 : d
            let url = urls[type] + d + '.png';
            if (this.img[type][d]) return this.img[type][d];
            else {
                const img = PointSimplifier.Render.Canvas.getImageContent(url, () => {
                    this.img[type][d] = img;
                    onLoad && onLoad();
                });
                return img;
            }

        },
        //当模式改变时
        handleModelChange() {
            if (!this.control.model) {
                this.map && this.map.clearMap();
                this.drawMassMarks();
                this.draw.pathSimplifierIns && this.draw.pathSimplifierIns.setData([{
                    name: '轨迹',
                    path: []
                }]);
                clearTimeout(this.trackTimer);
            } else if (this.track.tid) {
                this.drawInit();
                this.mark.pointSimplifierIns && this.mark.pointSimplifierIns.hide();
            }
        },
        //显示模式改变
        handelControlState() {
            this.drawMassMarks();
        },
        //开关热力图
        handleHeatMapControl() {

            if (this.heatmap) {
                this.heatmap.setDataSet({
                    data: [],
                    max: 100
                });
                return;
            }
            this.map.plugin(["AMap.Heatmap"], () => {
                //初始化heatmap对象
                this.heatmap = new window.AMap.Heatmap(this.map, {
                    radius: 25, //给定半径
                    opacity: [0, 0.8]
                    /*,
                    gradient:{
                        0.5: 'blue',
                        0.65: 'rgb(117,211,248)',
                        0.7: 'rgb(0, 255, 0)',
                        0.9: '#ffea00',
                        1.0: 'red'
                    }
                     */
                });
                //设置数据集：该数据为北京部分“公园”数据
                this.heatmap.setDataSet({
                    data: [],
                    max: 100
                });
            });
        }
    },
    //监听模版变量
    watch: {}
}
</script>
