<style lang="scss" scoped>
</style>
<template>
    <div>
        <el-cascader
                :options="options"
                :props="{'value':'text',label:'text',children:'City'}"
                v-model="selectedOptions"
                @change="handleChange">
        </el-cascader>
    </div>
</template>

<script type="text/ecmascript-6">
    import cityOptions from './citys'

    export default {
        name:'EtProvinceCity',
        //定义模版数据
        data() {
            return {
                options: cityOptions,
                selectedOptions: []
            }
        },
        props: [
            'value'
        ],
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            if (this.value)
                this.selectedOptions = this.value;
        },
        //定义事件方法
        methods: {
            handleChange(val) {
                this.$emit('input', val);
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
